import React, { useEffect, useMemo, useState } from "react";
import DropdownList from "react-widgets/DropdownList";
import {
  CongregationSpeaker,
  Speaker,
  Speakers,
  getSpeakers,
} from "../client/speakers";
import { Combobox } from "react-widgets";

export interface SpeakerLookupProps {
  name?: string;
  defaultValue?: string;
  placeholder?: string;
  congname?: string;
  onChange?: (speaker?: CongregationSpeaker) => void;
}
const SpeakerLookup = ({
  defaultValue = "",
  name,
  placeholder,
  congname,
  onChange,
}: SpeakerLookupProps) => {
  const [value, setValue] = useState<CongregationSpeaker>();
  const [data, setData] = useState<CongregationSpeaker[]>([]);

  useEffect(() => {
    getSpeakers().then((speakers) => {
      setData(speakers);
      setValue(speakers.find((speaker) => speaker.username === defaultValue));
    });
  }, [defaultValue]);

  const results = useMemo(
    () =>
      congname ? data.filter((speaker) => speaker.congname === congname) : data,
    [congname, data]
  );

  const onValueChange = (value: CongregationSpeaker) => {
    setValue(value);
    onChange?.(value);
  };
  return (
    <Combobox
      value={value}
      name={name}
      placeholder={placeholder}
      data={results}
      textField={"username"}
      groupBy={"congname"}
      onChange={onValueChange}
    />
  );
};

export default SpeakerLookup;
