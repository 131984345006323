import React, { useEffect, useState, type MouseEvent } from "react";
import { Button, Form, InputGroup, Table } from "react-bootstrap";
import {
  addSpeaker,
  deleteSpeaker,
  type Speaker,
  type Speakers,
  updateSpeaker,
  getSpeakersByCongregation,
  jumpUser,
} from "../../client";
import EditSpeaker from "./EditSpeaker";
import { Edit, FastForward, Search, Trash2 } from "react-feather";
import ConfirmModal from "../../components/ConfirmModal";
import { useUserContext } from "../../hooks/useUserContext";
import { useNavigate } from "react-router-dom";

export const ManageSpeakers = () => {
  const navigate = useNavigate();
  const { user, isSuperUser } = useUserContext();
  const [searchFilter, setSearchFilter] = useState("");
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [editSpeaker, setEditSpeaker] = useState<Speaker | undefined>(
    undefined
  );
  const [speakers, setSpeakers] = useState<Speakers>([]);

  const handleEditClose = () => {
    setShowEdit(false);
  };
  const handleDeleteClose = () => {
    setShowDelete(false);
  };

  const refresh = async () => {
    await getSpeakersByCongregation(user!.congid).then((speakers) => {
      setSpeakers(speakers);
    });
  };

  const showAddEditModal = (speaker?: Speaker) => {
    setEditSpeaker(speaker);
    setShowEdit(true);
  };
  const handleDeleteSpeaker = (
    event: MouseEvent<HTMLButtonElement>,
    speaker: Speaker
  ) => {
    event.stopPropagation();
    setEditSpeaker(speaker);
    setShowDelete(true);
  };
  const handleDeleteConfirm = async () => {
    if (editSpeaker != null) await deleteSpeaker(editSpeaker);
    setShowDelete(false);
    refresh();
  };
  const handleSave = async (speaker: Speaker) => {
    if (editSpeaker != null) {
      speaker.userid = editSpeaker.userid;
      speaker.congid = user!.congid;
      await updateSpeaker(speaker);
    } else {
      await addSpeaker(speaker);
    }
    setShowEdit(false);
    refresh();
  };

  const handleJump = async (
    event: MouseEvent<HTMLButtonElement>,
    speaker: Speaker
  ) => {
    event.stopPropagation();
    await jumpUser(speaker);
    navigate("/home");
  };

  useEffect(() => {
    refresh();
  }, [user]);

  const speakerFilter = (speaker: Speaker) =>
    searchFilter
      ? speaker.username.toLowerCase().indexOf(searchFilter.toLowerCase()) >= 0
      : true;

  return (
    <>
      <h1>Manage Speakers</h1>
      <InputGroup className="mb-3">
        <Form.Control
          placeholder="Search speakers..."
          aria-label="Search speakers"
          aria-describedby="basic-addon2"
          value={searchFilter}
          onChange={(event) => setSearchFilter(event.target.value)}
        />
        <InputGroup.Text id="basic-addon2">
          <Search />
        </InputGroup.Text>
        <Button
          onClick={() => {
            showAddEditModal();
          }}
          variant="primary"
        >
          Add Speaker
        </Button>
      </InputGroup>
      <EditSpeaker
        show={showEdit}
        onClose={handleEditClose}
        onSave={handleSave}
        speaker={editSpeaker!!}
      />
      <ConfirmModal
        show={showDelete}
        onClose={handleDeleteClose}
        onConfirm={handleDeleteConfirm}
        message={`Are you sure you want to delete ${editSpeaker?.username}?`}
      />
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Name</th>
            <th>Mobile</th>
            <th>Email</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {speakers.filter(speakerFilter).map((speaker) => (
            <tr
              key={speaker.userid}
              onClick={() => {
                showAddEditModal(speaker);
              }}
            >
              <td>{speaker.username}</td>
              <td>{speaker.usermobile}</td>
              <td>{speaker.useremail}</td>
              <td>
                <Button variant="icon" size="sm">
                  <Edit />
                </Button>
                <Button
                  variant="icon"
                  size="sm"
                  onClick={(event) => {
                    handleDeleteSpeaker(event, speaker);
                  }}
                >
                  <Trash2 />
                </Button>
                {isSuperUser() && (
                  <Button
                    variant="icon"
                    size="sm"
                    onClick={(event) => handleJump(event, speaker)}
                  >
                    <FastForward />
                  </Button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default ManageSpeakers;
