import React from "react";
import ReactDOM from "react-dom";
import "bootswatch/dist/pulse/bootstrap.min.css";
import AppRoutes from "./routes";
import { BrowserRouter as Router } from "react-router-dom";
import Menu from "./components/Menu";
import axios from "axios";
import UserContextProvider from "./hooks/useUserContext";

import "react-widgets/scss/styles.scss";

axios.defaults.withCredentials = true;

export const BASE_URL =
  process.env.NODE_ENV !== "production" ? "http://localhost:3001" : "";

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <UserContextProvider>
        <Menu />
        <AppRoutes />
      </UserContextProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);
