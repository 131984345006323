import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { type HomeTalk } from "../../client/home";
import { format } from "date-fns";
import SpeakerLookup from "../../components/SpeakerLookup";
import OutlineLookup from "../../components/OutlineLookup";
import CongregationLookup from "../../components/CongregationLookup";
import { useUserContext } from "../../hooks/useUserContext";

export interface EditHomeTalkProps {
  show: boolean;
  talk: HomeTalk;
  onSave: (talk: HomeTalk) => void;
  onClose: VoidFunction;
}
const EditHomeTalk = ({ show, onSave, onClose, talk }: EditHomeTalkProps) => {
  const { user } = useUserContext();
  const [congname, setCongname] = useState<string>();

  useEffect(() => {
    setCongname(talk.congname);
  }, [talk, show]);

  const onFormSubmit = (e: any) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const speaker = Object.fromEntries(formData.entries()) as any;
    speaker.role = 0;
    onSave(speaker);
  };
  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        {talk.talkid != null ? (
          <Modal.Title>Talk on {format(talk.date, "dd MMMM yyyy")}</Modal.Title>
        ) : (
          <Modal.Title>
            Add talk on {format(talk.date, "dd MMMM yyyy")}
          </Modal.Title>
        )}
      </Modal.Header>
      <Form onSubmit={onFormSubmit}>
        <Modal.Body>
          <Form.Group controlId="name">
            <Form.Label>Name</Form.Label>
            <SpeakerLookup
              placeholder="Enter speakers name"
              name="name"
              defaultValue={talk?.name}
              congname={congname}
              onChange={(speaker) => setCongname(speaker?.congname)}
            />
          </Form.Group>
          <Form.Group controlId="congname">
            <Form.Label>From Congregation</Form.Label>
            <CongregationLookup
              placeholder="Enter congregation name"
              name="congname"
              defaultValue={congname}
              onChange={(speaker) => setCongname(speaker?.congname)}
            />
          </Form.Group>
          <Form.Group controlId="outlineno">
            <Form.Label>Outline</Form.Label>
            <OutlineLookup
              placeholder="Choose talk outline"
              name="outlineno"
              defaultValue={Number(talk?.outlineno)}
            />
          </Form.Group>
          <Form.Group controlId="chairman">
            <Form.Label>Chairman</Form.Label>
            <SpeakerLookup
              placeholder="Enter chairmans name"
              name="chairman"
              defaultValue={talk?.chairman}
              congname={user?.congname}
            />
          </Form.Group>
          <Form.Group controlId="wtreader">
            <Form.Label>Watchtower Reader</Form.Label>
            <SpeakerLookup
              placeholder="Enter readers name"
              name="wtreader"
              defaultValue={talk?.wtreader}
              congname={user?.congname}
            />
          </Form.Group>
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={onClose} variant="secondary">
            Close
          </Button>
          <Button type="submit" variant="primary">
            Save
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default EditHomeTalk;
