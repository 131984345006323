import React, { useEffect, useMemo, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { getSpeakerOutlines, type Speaker } from "../../client/speakers";
import Multiselect from "react-widgets/Multiselect";
import OutlineMultiselect from "../../components/OutlineMultiSelect";
import { Outlines, getOutlines } from "../../client/outlines";

export interface EditSpeakerProps {
  show: boolean;
  speaker: Speaker;
  onSave: (speaker: Speaker) => void;
  onClose: VoidFunction;
}
const EditSpeaker = ({ show, onSave, onClose, speaker }: EditSpeakerProps) => {
  const [role, setRole] = useState<number>(0);
  const [outlines, setOutlines] = useState<Array<number>>([]);

  const HOME_TALK_ROLE = 1 << 0;
  const AWAY_TALK_ROLE = 1 << 1;
  const CHAIRMAN_ROLE = 1 << 2;
  const WT_READER_ROLE = 1 << 3;

  const roles = useMemo(() => {
    const result = [];
    if (speaker) {
      if (speaker.role & HOME_TALK_ROLE) result.push(1);
      if (speaker.role & AWAY_TALK_ROLE) result.push(2);
      if (speaker.role & CHAIRMAN_ROLE) result.push(4);
      if (speaker.role & WT_READER_ROLE) result.push(8);
    }
    setRole(speaker?.role || 0);
    setOutlines(speaker?.outlines || []);
    return result;
  }, [speaker?.role, speaker?.userid]);

  const updateRole = (roles: number[], speaker: Speaker) => {
    let role = 0;
    if (roles.includes(1)) role |= HOME_TALK_ROLE;
    if (roles.includes(2)) role |= AWAY_TALK_ROLE;
    if (roles.includes(4)) role |= CHAIRMAN_ROLE;
    if (roles.includes(8)) role |= WT_READER_ROLE;
    setRole(role);
  };

  const onFormSubmit = (e: any) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const speaker = Object.fromEntries(formData.entries()) as any;
    speaker.role = role;
    speaker.outlines = outlines;
    onSave(speaker);
  };

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        {speaker != null ? (
          <Modal.Title>Edit Speaker</Modal.Title>
        ) : (
          <Modal.Title>Add Speaker</Modal.Title>
        )}
      </Modal.Header>
      <Form onSubmit={onFormSubmit}>
        <Modal.Body>
          <Form.Group controlId="username">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter speakers name"
              name="username"
              defaultValue={speaker?.username}
            />
          </Form.Group>
          <Form.Group controlId="usermobile">
            <Form.Label>Mobile No.</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter speakers mobile number"
              name="usermobile"
              defaultValue={speaker?.usermobile}
            />
          </Form.Group>
          <Form.Group controlId="useremail">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter speakers email address"
              name="useremail"
              defaultValue={speaker?.useremail}
            />
          </Form.Group>
          <Form.Group controlId="role">
            <Form.Label>Role(s)</Form.Label>
            <Multiselect
              dataKey="id"
              textField="rolename"
              defaultValue={roles}
              data={[
                { id: 1, rolename: "Home Talks" },
                { id: 2, rolename: "Away Talks" },
                { id: 4, rolename: "Chairman" },
                { id: 8, rolename: "Watchtower Reader" },
              ]}
              onChange={(roles) =>
                updateRole(
                  roles.map((role) => role.id),
                  speaker
                )
              }
            />
          </Form.Group>
          <Form.Group controlId="outlines">
            <Form.Label>Outlines</Form.Label>
            <OutlineMultiselect
              outlines={outlines}
              name="outlines"
              placeholder="Choose speaker outlines"
              onChange={(outlines) => setOutlines(outlines || [])}
            />
          </Form.Group>
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={onClose} variant="secondary">
            Close
          </Button>
          <Button type="submit" variant="primary">
            Save changes
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default EditSpeaker;
