import React, { useEffect, useState } from "react";
import Multiselect from "react-widgets/Multiselect";
import { Outline, Outlines, getOutlines } from "../client/outlines";
import { Speaker } from "../client/speakers";

export interface OutlineLookupProps {
  name?: string;
  outlines?: number[];
  placeholder?: string;
  onChange?: (outlines?: number[]) => void;
}
const OutlineMultiselect = ({
  outlines,
  name,
  placeholder,
  onChange,
}: OutlineLookupProps) => {
  const [data, setData] = useState<Outlines>([]);

  useEffect(() => {
    getOutlines().then((outlines) => {
      setData(outlines);
    });
  }, [outlines]);

  return (
    <Multiselect
      placeholder={placeholder}
      defaultValue={outlines}
      data={data}
      dataKey={"outlineno"}
      textField={(outline) => `${outline.outlineno} - ${outline.outlinetitle}`}
      onChange={(outlines) =>
        onChange?.(outlines?.map((outline) => outline.outlineno))
      }
      // renderValue={({ item }) => `${item.outlineno} - ${item.outlinetitle}`}
    />
  );
};

export default OutlineMultiselect;
