import React from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { type Congregation } from "../../client/congregations";
export interface EditCongregationProps {
  show: boolean;
  congregation?: Congregation;
  onSave: (congregation: Congregation) => void;
  onClose: VoidFunction;
}
const EditCongregation = ({
  show,
  onSave,
  onClose,
  congregation,
}: EditCongregationProps) => {
  const onFormSubmit = (e: any) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const congregation = Object.fromEntries(formData.entries()) as any;
    onSave(congregation);
  };
  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        {congregation != null ? (
          <Modal.Title>Edit Congregation</Modal.Title>
        ) : (
          <Modal.Title>Add Congregation</Modal.Title>
        )}
      </Modal.Header>
      <Form onSubmit={onFormSubmit}>
        <Modal.Body>
          <Form.Group controlId="congname">
            <Form.Label>Name</Form.Label>
            <Form.Control
              placeholder="Enter congregation name"
              name="congname"
              defaultValue={congregation?.congname}
            />
          </Form.Group>
          <Form.Group controlId="addressline1">
            <Form.Label>Address</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter address"
              name="addressline1"
              defaultValue={congregation?.addressline1}
            />
          </Form.Group>
          <Form.Group controlId="addressline2">
            <Form.Label></Form.Label>
            <Form.Control
              type="text"
              name="addressline2"
              defaultValue={congregation?.addressline2}
            />
          </Form.Group>
          <Form.Group controlId="town">
            <Form.Label>Town</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter town"
              name="town"
              defaultValue={congregation?.town}
            />
          </Form.Group>
          <Form.Group controlId="postcode">
            <Form.Label>Post code</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter post code"
              name="postcode"
              defaultValue={congregation?.postcode}
            />
          </Form.Group>
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={onClose} variant="secondary">
            Close
          </Button>
          <Button type="submit" variant="primary">
            Save changes
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default EditCongregation;
