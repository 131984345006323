import React from "react";
import { Container, ToastContainer } from "react-bootstrap";
import { Routes, Route } from "react-router-dom";
import AwayTalks from "../pages/away/AwayTalks";
import HomeTalks from "../pages/home/HomeTalks";
import ManageOutlines from "../pages/outlines/ManageOutlines";
import ManageCongregations from "../pages/congregations/ManageCongregations";
import Login from "../pages/Login";
import Logout from "../pages/Logout";
import ManageSpeakers from "../pages/speakers/ManageSpeakers";
import MyAccount from "../pages/MyAccount";
import SecurePage from "../components/SecurePage";
import Welcome from "../pages/Welcome";

function AppRoutes() {
  return (
    <Container>
      <Routes>
        <Route path="login" element={<Login />} />
        <Route path="logout" element={<Logout />} />
        <Route path="myaccount" element={<SecurePage><MyAccount /></SecurePage>} />
        <Route path="/" element={<Welcome />} />
        <Route path="home" element={<SecurePage><HomeTalks /></SecurePage>} />
        <Route path="away" element={<SecurePage><AwayTalks /></SecurePage>} />
        <Route path="outlines" element={<SecurePage><ManageOutlines /></SecurePage>} />
        <Route path="congregations" element={<SecurePage><ManageCongregations /></SecurePage>} />
        <Route path="speakers" element={<SecurePage><ManageSpeakers /></SecurePage>} />
        <Route
          path="*"
          element={
            <p>
              <h1>Nothing here!</h1>This is not the page you are looking for
            </p>
          }
        />
      </Routes>
    </Container>
  );
}

export default AppRoutes;
