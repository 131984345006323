import React, { useEffect, useState, type MouseEvent } from "react";
import { Button, Form, InputGroup, Table } from "react-bootstrap";
import {
  type Congregation,
  type Congregations,
  addCongregation,
  deleteCongregation,
  getCongregations,
  updateCongregation,
  jumpCongregation,
} from "../../client";
import ConfirmModal from "../../components/ConfirmModal";
import EditCongregation from "./EditCongregation";
import { Edit, FastForward, Search, Trash2 } from "react-feather";
import { useUserContext } from "../../hooks/useUserContext";
import { useNavigate } from "react-router-dom";

export const ManageCongregations = () => {
  const navigate = useNavigate();
  const { isSuperUser } = useUserContext();
  const [searchFilter, setSearchFilter] = useState("");
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [editCongregation, setEditCongregation] = useState<
    Congregation | undefined
  >(undefined);
  const [congregations, setCongregations] = useState<Congregations>([]);

  const handleEditClose = () => {
    setShowEdit(false);
  };
  const handleDeleteClose = () => {
    setShowDelete(false);
  };

  const refresh = async () => {
    await getCongregations().then((congregations) => {
      setCongregations(congregations);
    });
  };

  const showAddEditModal = (congregation?: Congregation) => {
    setEditCongregation(congregation);
    setShowEdit(true);
  };
  const handleDeleteCongregation = (
    event: MouseEvent<HTMLButtonElement>,
    congregation: Congregation
  ) => {
    event.stopPropagation();
    setEditCongregation(congregation);
    setShowDelete(true);
  };
  const handleDeleteConfirm = async () => {
    if (editCongregation != null) await deleteCongregation(editCongregation);
    setShowDelete(false);
    refresh();
  };
  const handleSave = async (congregation: Congregation) => {
    if (editCongregation != null) {
      await updateCongregation(congregation, editCongregation);
    } else {
      await addCongregation(congregation);
    }
    setShowEdit(false);
    refresh();
  };

  const handleJump = async (
    event: MouseEvent<HTMLButtonElement>,
    congregation: Congregation
  ) => {
    event.stopPropagation();
    await jumpCongregation(congregation);
    navigate("/home");
  };

  useEffect(() => {
    refresh();
  }, []);

  const congregationFilter = (congregation: Congregation) =>
    searchFilter
      ? congregation.congname
          .toLowerCase()
          .indexOf(searchFilter.toLowerCase()) >= 0
      : true;
  return (
    <>
      <h1>Manage Congregations</h1>

      <InputGroup className="mb-3">
        <Form.Control
          placeholder="Search congregations..."
          aria-label="Search congregations"
          aria-describedby="basic-addon2"
          value={searchFilter}
          onChange={(event) => setSearchFilter(event.target.value)}
        />
        <InputGroup.Text id="basic-addon2">
          <Search />
        </InputGroup.Text>
        <Button
          onClick={() => {
            showAddEditModal();
          }}
          variant="primary"
        >
          Add Congregation
        </Button>
      </InputGroup>
      <EditCongregation
        show={showEdit}
        onClose={handleEditClose}
        onSave={handleSave}
        congregation={editCongregation}
      />
      <ConfirmModal
        show={showDelete}
        onClose={handleDeleteClose}
        onConfirm={handleDeleteConfirm}
        message={`Are you sure you want to delete ${editCongregation?.congname} congregation?`}
      />

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Name</th>
            <th>Address</th>
            <th>Town</th>
            <th>Postcode</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {congregations.filter(congregationFilter).map((congregation) => (
            <tr
              key={congregation.congid}
              onClick={() => {
                showAddEditModal(congregation);
              }}
            >
              <td>{congregation.congname}</td>
              <td>
                {congregation.addressline1} {congregation.addressline2}
              </td>
              <td>{congregation.town}</td>
              <td>{congregation.postcode}</td>
              <td>
                <Button variant="icon" size="sm">
                  <Edit />
                </Button>
                <Button
                  variant="icon"
                  size="sm"
                  onClick={(event) => {
                    handleDeleteCongregation(event, congregation);
                  }}
                >
                  <Trash2 />
                </Button>
                {isSuperUser() && (
                  <Button
                    variant="icon"
                    size="sm"
                    onClick={(event) => handleJump(event, congregation)}
                  >
                    <FastForward />
                  </Button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default ManageCongregations;
