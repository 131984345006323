import React, { MouseEventHandler } from "react";
import { Button } from "react-bootstrap";
import { AlertTriangle } from "react-feather";

export interface WarningProps {
  tooltip: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}
const Warning = ({ tooltip, onClick }: WarningProps) => (
  <span title="Speaker is not assigned this talk (Click to add)">
  <Button variant="icon" size="sm" onClick={onClick} title={tooltip}>
    <AlertTriangle />
  </Button>
  </span>
);

export default Warning;
