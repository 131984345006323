import React, { useEffect, useState } from "react";
import DropdownList from "react-widgets/DropdownList";
import { Outline, Outlines, getOutlines } from "../client/outlines";
import { Speaker } from "../client/speakers";

export interface OutlineLookupProps {
  name?: string;
  defaultValue?: number;
  placeholder?: string;
  onChange?: (outline?: Outline) => void;
}
const OutlineLookup = ({
  defaultValue,
  name,
  placeholder,
}: OutlineLookupProps) => {
  const [data, setData] = useState<Outlines>([]);
  useEffect(() => {
    getOutlines().then((outlines) => {
      setData(outlines);
    });
  }, [defaultValue]);

  return (
    <DropdownList
      defaultValue={defaultValue}
      name={name}
      placeholder={placeholder}
      data={data}
      dataKey={"outlineno"}
      textField={(outline) => `${outline.outlineno} - ${outline.outlinetitle}`}
      renderValue={({ item }) => `${item.outlineno} - ${item.outlinetitle}`}
      filter={({ outlineno, outlinetitle }, value) =>
        outlineno.toString().indexOf(value) >= 0 ||
        outlinetitle.toLowerCase().indexOf(value.toLowerCase()) >= 0
      }
    />
  );
};

export default OutlineLookup;
