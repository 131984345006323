import React, { useEffect, useState, type MouseEvent } from "react";
import { Button, Form, InputGroup, Table } from "react-bootstrap";
import {
  addOutline,
  deleteOutline,
  getOutlines,
  type Outline,
  type Outlines,
  updateOutline,
} from "../../client";
import EditOutline from "./EditOutline";
import { Edit, Search, Trash2 } from "react-feather";
import ConfirmModal from "../../components/ConfirmModal";
import { format } from "date-fns";

export const ManageOutlines = () => {
  const [searchFilter, setSearchFilter] = useState("");
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [editOutline, setEditOutline] = useState<Outline | undefined>(
    undefined
  );
  const [outlines, setOutlines] = useState<Outlines>([]);

  const handleEditClose = () => {
    setShowEdit(false);
  };
  const handleDeleteClose = () => {
    setShowDelete(false);
  };

  const refresh = async () => {
    await getOutlines().then((outlines) => {
      setOutlines(outlines);
    });
  };

  const showAddEditModal = (outline?: Outline) => {
    setEditOutline(outline);
    setShowEdit(true);
  };
  const handleDeleteOutline = (
    event: MouseEvent<HTMLButtonElement>,
    outline: Outline
  ) => {
    event.stopPropagation();
    setEditOutline(outline);
    setShowDelete(true);
  };
  const handleDeleteConfirm = async () => {
    if (editOutline != null) await deleteOutline(editOutline);
    setShowDelete(false);
    refresh();
  };
  const handleSave = async (outline: Outline) => {
    if (editOutline != null) {
      await updateOutline(outline, editOutline);
    } else {
      await addOutline(outline);
    }
    setShowEdit(false);
    refresh();
  };

  useEffect(() => {
    refresh();
  }, []);

  const outlineFilter = (outline: Outline) =>
    searchFilter
      ? outline.outlinetitle
          .toLowerCase()
          .indexOf(searchFilter.toLowerCase()) >= 0
      : true;
  return (
    <>
      <h1>Manage Outlines</h1>
      <InputGroup className="mb-3">
        <Form.Control
          placeholder="Search outlines..."
          aria-label="Search outlines"
          aria-describedby="basic-addon2"
          value={searchFilter}
          onChange={(event) => setSearchFilter(event.target.value)}
        />
        <InputGroup.Text id="basic-addon2">
          <Search />
        </InputGroup.Text>
        <Button
          onClick={() => {
            showAddEditModal();
          }}
          variant="primary"
        >
          Add Outline
        </Button>
      </InputGroup>
      <EditOutline
        show={showEdit}
        onClose={handleEditClose}
        onSave={handleSave}
        outline={editOutline}
      />
      <ConfirmModal
        show={showDelete}
        onClose={handleDeleteClose}
        onConfirm={handleDeleteConfirm}
        message={`Are you sure you want to delete outline no. ${editOutline?.outlineno}?`}
      />
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Talk No.</th>
            <th>Title</th>
            <th>Last Heard</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {outlines.filter(outlineFilter).map((outline) => (
            <tr
              key={outline.outlineno}
              onClick={() => {
                showAddEditModal(outline);
              }}
            >
              <td>{outline.outlineno}</td>
              <td>{outline.outlinetitle}</td>
              <td>
                {outline.lastdate
                  ? format(outline.lastdate, "dd MMMM yyyy")
                  : "Not heard yet"}
              </td>
              <td>
                <Button variant="icon" size="sm">
                  <Edit />
                </Button>
                <Button
                  variant="icon"
                  size="sm"
                  onClick={(event) => {
                    handleDeleteOutline(event, outline);
                  }}
                >
                  <Trash2 />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default ManageOutlines;
