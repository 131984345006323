import React, { useState } from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { checkUser, logout } from "../client/user";
import { useUserContext } from "../hooks/useUserContext";
import { User } from "react-feather";

function Menu() {
  const navigate = useNavigate();
  const { user, setUser } = useUserContext();

  if (user != null) {
    return (
      <Navbar expand="lg" bg="dark" variant="dark">
        <Container fluid>
          <LinkContainer to="/home">
            <Navbar.Brand href="/">{user.congname} Talks</Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav>
              <LinkContainer to="/away">
                <Nav.Link>Away Talks</Nav.Link>
              </LinkContainer>
            </Nav>
          </Navbar.Collapse>
            <Nav>
              <NavDropdown title="Manage" id="basic-nav-dropdown" align="end">
                <NavDropdown.Item href="/outlines">Outlines</NavDropdown.Item>
                <NavDropdown.Item href="/congregations">
                  Congregations
                </NavDropdown.Item>
                <NavDropdown.Item href="/speakers">Speakers</NavDropdown.Item>
              </NavDropdown>
              
              <NavDropdown title={<User />} id="basic-nav-dropdown" align="end">
                <NavDropdown.Item href="/myaccount">
                  My Account
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={async () => {
                    await logout()
                      .then(() => {
                        setUser(undefined);
                      })
                      .then(() => {
                        navigate("/logout");
                      });
                  }}
                >
                  Sign Out
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
        </Container>
      </Navbar>
    );
  } else {
    return (
      <Navbar expand="lg" bg="dark" variant="dark">
        <Container>
          <LinkContainer to="/">
            <Navbar.Brand href="/">Talks</Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <LinkContainer to="/login">
                <Nav.Link>Login</Nav.Link>
              </LinkContainer>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  }
}

export default Menu;
