import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { format } from "date-fns";
import SpeakerLookup from "../../components/SpeakerLookup";
import OutlineLookup from "../../components/OutlineLookup";
import CongregationLookup from "../../components/CongregationLookup";
import { AwayTalk } from "../../client/away";
import { getCongTime, getTalkTime } from "./AwayTalks";
import { useUserContext } from "../../hooks/useUserContext";

export interface EditAwayTalkProps {
  show: boolean;
  talk: AwayTalk;
  onSave: (talk: AwayTalk) => void;
  onClose: VoidFunction;
}

const EditAwayTalk = ({ show, onSave, onClose, talk }: EditAwayTalkProps) => {
  const { user } = useUserContext();
  const [time, setTime] = useState<string>(getTalkTime(talk));

  console.log({ talk });
  useEffect(() => {
    setTime(getTalkTime(talk) || "");
  }, [talk, show]);

  const onFormSubmit = (e: any) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const speaker = Object.fromEntries(formData.entries()) as any;
    speaker.role = 0;
    onSave(speaker);
  };
  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        {talk.talkid != null ? (
          <Modal.Title>Talk on {format(talk.date, "dd MMMM yyyy")}</Modal.Title>
        ) : (
          <Modal.Title>
            Add talk on {format(talk.date, "dd MMMM yyyy")}
          </Modal.Title>
        )}
      </Modal.Header>
      <Form onSubmit={onFormSubmit}>
        <Modal.Body>
          <Form.Group controlId="name">
            <Form.Label>Name</Form.Label>
            <SpeakerLookup
              placeholder="Enter speakers name"
              name="name"
              congname={user!.congname}
              defaultValue={talk?.name}
            />
          </Form.Group>
          <Form.Group controlId="congname">
            <Form.Label>To Congregation</Form.Label>
            <CongregationLookup
              placeholder="Enter congregation name"
              name="congname"
              defaultValue={talk?.congname}
              onChange={(congregation) =>
                setTime(getCongTime(talk, congregation) || time)
              }
            />
          </Form.Group>
          <Form.Group controlId="outlineno">
            <Form.Label>Outline</Form.Label>
            <OutlineLookup
              placeholder="Choose talk outline"
              name="outlineno"
              defaultValue={Number(talk?.outlineno)}
            />
          </Form.Group>
          <Form.Group controlId="time">
            <Form.Label>Time</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter time of meeting"
              name="time"
              value={time}
              onChange={(event) => setTime(event.target.value)}
            />
          </Form.Group>
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={onClose} variant="secondary">
            Close
          </Button>
          <Button type="submit" variant="primary">
            Save
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default EditAwayTalk;
