import React, { useEffect, useMemo, useState } from "react";
import DropdownList from "react-widgets/DropdownList";
import { Congregation, getCongregations } from "../client/congregations";
import { Combobox } from "react-widgets/cjs";

export interface CongregationLookupProps {
  name?: string;
  defaultValue?: string;
  placeholder?: string;
  onChange?: (congregation?: Congregation) => void;
}
const CongregationLookup = ({
  defaultValue = "",
  name,
  placeholder,
  onChange,
}: CongregationLookupProps) => {
  const [value, setValue] = useState<Congregation>();
  const [congregations, setCongregations] = useState<Congregation[]>([]);

  useEffect(() => {
    getCongregations().then((congregations) => {
      setCongregations(congregations);
      setValue(
        congregations.find(
          (congregation) => congregation.congname === defaultValue
        )
      );
    });
  }, [defaultValue]);

  const onValueChange = (value: Congregation) => {
    setValue(value);
    onChange?.(value);
  };

  return (
    <Combobox
      value={value}
      name={name}
      placeholder={placeholder}
      data={congregations}
      onChange={onValueChange}
      textField={"congname"}
    />
  );
};

export default CongregationLookup;
