import axios from "axios";
import { BASE_URL } from "..";

export interface AwayTalk {
  talkid: number;
  dateid: number;
  sunday: string;
  date: Date;
  name: string;
  userid: number;
  outlineno: number;
  outlinetitle: string;
  congname: string;
  timeodd: string;
  timeeven: string;
  year: 1 | 0;
  week: number;
  talkcount: number;
  outlinecount: number;
}
export type AwayTalks = AwayTalk[];

export const getAwayTalks = async (week: number) =>
  await axios<AwayTalks>({
    method: "get",
    url: `${BASE_URL}/api/away`,
    params: {
      week,
    },
  })
    .then((response) => response.data)
    .then((talks) =>
      talks.map((talk) => ({ ...talk, date: new Date(talk.sunday) }))
    );

export const addAwayTalk = async (talk: AwayTalk) =>
  await axios({
    method: "post",
    url: `${BASE_URL}/api/away`,
    data: talk,
  }).then((response) => response.data);

export const updateAwayTalk = async (talk: AwayTalk) =>
  await axios({
    method: "put",
    url: `${BASE_URL}/api/away/${talk.talkid}`,
    data: talk,
  }).then((response) => response.data);

export const deleteAwayTalk = async (talk: AwayTalk) =>
  await axios({
    method: "delete",
    url: `${BASE_URL}/api/away/${talk.talkid}`,
  }).then((response) => response.data);
