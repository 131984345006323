import axios from "axios";
import { BASE_URL } from "..";

export interface Outline {
  outlineno: number;
  outlinetitle: string;
  lastdate?: Date;
}
export type Outlines = Outline[];

export const getOutlines = async () =>
  await axios<Outlines>({
    method: "get",
    url: `${BASE_URL}/api/outlines`,
  })
    .then((response) => response.data)
    .then((outlines) =>
      outlines.map((outline) => ({
        ...outline,
        lastdate: outline.lastdate ? new Date(outline.lastdate) : undefined,
      }))
    );

export const addOutline = async (outline: Outline) =>
  await axios<Outline>({
    method: "post",
    url: `${BASE_URL}/api/outlines`,
    data: outline,
  }).then((response) => response.data);

export const updateOutline = async (outline: Outline, oldOutline: Outline) =>
  await axios<Outline>({
    method: "put",
    url: `${BASE_URL}/api/outlines/${oldOutline.outlineno}`,
    data: outline,
  }).then((response) => response.data);

export const deleteOutline = async (outline: Outline) =>
  await axios<Outline>({
    method: "delete",
    url: `${BASE_URL}/api/outlines/${outline.outlineno}`,
  }).then((response) => response.data);
