import React from "react";
import { Button, Form } from "react-bootstrap";
import { checkUser, login } from "../client/user";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "..";

const Login = () => {
  const navigate = useNavigate();

  const onFormSubmit = async (e: any) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const user = Object.fromEntries(formData.entries()) as any;
    login(user)
      .then(() => navigate("/home"))
      .catch(() => alert("Username or password incorrect, try again."));
  };
  return (
    <Form onSubmit={onFormSubmit}>
      <h1>Login {BASE_URL}</h1>
      <Form.Group controlId="congname">
        <Form.Label>Email</Form.Label>
        <Form.Control placeholder="Enter your email address" name="email" />
      </Form.Group>
      <Form.Group controlId="addressline1">
        <Form.Label>Password</Form.Label>
        <Form.Control
          type="password"
          placeholder="Enter your password"
          name="password"
        />
      </Form.Group>

      <Button type="submit" variant="primary">
        Login
      </Button>
    </Form>
  );
};

export default Login;
