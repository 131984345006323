import React from "react";
import { Button, Form } from "react-bootstrap";
import { useUserContext } from "../hooks/useUserContext";
import { updatePassword, updateSpeaker } from "../client/speakers";

const MyAccount = () => {
  const { user } = useUserContext();

  const onUpdateUser = async (e: any) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const updateUser = Object.fromEntries(formData.entries()) as any;
    await updateSpeaker({ ...user, ...updateUser });
  };
  const onUpdatePassword = async (e: any) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const updateUser = Object.fromEntries(formData.entries()) as any;
    await updatePassword({ ...user, ...updateUser });
  };

  return (
    <>
      <Form onSubmit={onUpdateUser}>
        <h1>My Account</h1>
        <h2>Personal Details</h2>
        <Form.Group controlId="username">
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter your name"
            name="username"
            defaultValue={user?.username}
          />
        </Form.Group>
        <Form.Group controlId="usermobile">
          <Form.Label>Mobile No.</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter your mobile number"
            name="usermobile"
            defaultValue={user?.usermobile}
          />
        </Form.Group>
        <Form.Group controlId="useremail">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter speakers email address"
            name="useremail"
            defaultValue={user?.useremail}
          />
        </Form.Group>
        <Button type="submit" variant="primary">
          Update
        </Button>
      </Form>

      <h2>Change Password</h2>
      <Form onSubmit={onUpdatePassword}>
        <Form.Group controlId="password">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Enter new password"
            name="password"
          />
        </Form.Group>
        <Form.Group controlId="confirmPassword">
          <Form.Label>Confirm</Form.Label>
          <Form.Control
            type="password"
            placeholder="Confirm new password"
            name="confirmPassword"
          />
        </Form.Group>

        <Button type="submit" variant="primary">
          Change Password
        </Button>
      </Form>
    </>
  );
};

export default MyAccount;
