import React, { useEffect, useState, MouseEvent } from "react";
import { Button, Table } from "react-bootstrap";
import {
  type AwayTalks,
  getAwayTalks,
  AwayTalk,
  deleteAwayTalk,
  updateAwayTalk,
  addAwayTalk,
  Congregation,
  addSpeakerOutline
} from "../../client";
import { format } from "date-fns";
import ConfirmModal from "../../components/ConfirmModal";
import EditAwayTalk from "./EditAwayTalk";
import {
  AlertTriangle,
  ChevronLeft,
  ChevronRight,
  Edit,
  Plus,
  Printer,
  Trash2,
} from "react-feather";
import { BASE_URL } from "../..";
import Warning from "../../components/Warning";
import { useUserContext } from "../../hooks/useUserContext";

export const getTalkTime = (talk: AwayTalk) => {
  if (talk.year === 1) {
    return talk.timeodd;
  } else if (talk.year === 0) {
    return talk.timeeven;
  } else {
    return "";
  }
};

export const getCongTime = (talk: AwayTalk, congregation?: Congregation) => {
  if (congregation) {
    if (talk.year) {
      return congregation.timeodd;
    } else {
      return congregation.timeeven;
    }
  }
};

const ManageAwayTalks = () => {
  const user = useUserContext();
  
  const [week, setWeek] = useState(0);
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [editTalk, setEditTalk] = useState<AwayTalk | undefined>(undefined);
  const [talks, setTalks] = useState<AwayTalks>([]);
  const [showAddTalk, setShowAddTalk] = useState(false);

  const handleCloseAddTalkModal = () => setShowAddTalk(false);

  const addTalkOutline = async () => {
    if (editTalk) {
      await addSpeakerOutline(editTalk.userid, editTalk.outlineno);
      handleCloseAddTalkModal();
      await refresh(week);
    }
  };

  const handleEditClose = () => {
    setShowEdit(false);
  };
  const handleDeleteClose = () => {
    setShowDelete(false);
  };

  const refresh = async (week: number) => {
    setWeek(week);
    await getAwayTalks(week).then((talks) => {
      setTalks(talks);
    });
  };

  const skipWeeks = async (skipWeeks: number) => {
    await refresh(week + skipWeeks);
  };

  const showAddEditModal = (event: MouseEvent<any>, talk?: AwayTalk) => {
    event.stopPropagation();
    setEditTalk(talk);
    setShowEdit(true);
  };
  const handleDeleteTalk = (
    event: MouseEvent<HTMLButtonElement>,
    talk: AwayTalk
  ) => {
    event.stopPropagation();
    setEditTalk(talk);
    setShowDelete(true);
  };
  const handleAddTalkOutline = (
    event: MouseEvent<HTMLButtonElement>,
    talk: AwayTalk
  ) => {
    event.stopPropagation();
    setEditTalk(talk);
    setShowAddTalk(true);
  };
  const handleDeleteConfirm = async () => {
    if (editTalk != null) await deleteAwayTalk(editTalk);
    setShowDelete(false);
    refresh(week);
  };
  const handleSave = async (talk: AwayTalk) => {
    if (editTalk != null && editTalk.talkid) {
      talk.talkid = editTalk.talkid;
      talk.year = editTalk!!.year;

      await updateAwayTalk(talk);
    } else {
      talk.dateid = editTalk!!.dateid;
      talk.year = editTalk!!.year;

      await addAwayTalk(talk);
    }
    setShowEdit(false);
    refresh(week);
  };

  useEffect(() => {
    refresh(week);
  }, []);

  return (
    <>
      <h1>Away Talks</h1>
      {editTalk != null && (
        <EditAwayTalk
          show={showEdit}
          onClose={handleEditClose}
          onSave={handleSave}
          talk={editTalk}
        />
      )}
      {editTalk != null && (
        <>
        <ConfirmModal
          show={showDelete}
          onClose={handleDeleteClose}
          onConfirm={handleDeleteConfirm}
          message={`Are you sure you want to delete talk on ${format(
            editTalk.date,
            "dd MMMM yyyy"
          )}?`}
        />
        <ConfirmModal
            show={showAddTalk}
            title="Talk not assigned"
            message={`Add talk ${editTalk.outlineno} - ${editTalk.outlinetitle} to ${editTalk.name}'s reportoire?`}
            onConfirm={addTalkOutline}
            onClose={handleCloseAddTalkModal}
          />
          </>
      )}
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>
              <Button variant="icon" size="sm" onClick={() => skipWeeks(-10)}>
                <ChevronLeft />
              </Button>
              Date
              <Button variant="icon" size="sm" onClick={() => skipWeeks(+10)}>
                <ChevronRight />
              </Button>
            </th>
            <th>Speaker</th>
            <th>Talk No.</th>
            <th>Title</th>
            <th>To Congregation</th>
            <th>Time</th>
            <th>
              <a href={`${BASE_URL}/api/print/away?week=${week}`}>
                <Button variant="icon" size="sm">
                  <Printer />
                </Button>
              </a>
            </th>
          </tr>
        </thead>
        <tbody>
          {talks.map((talk, index) => (
            <tr
              className="selectable"
              key={index}
              onClick={(event) => {
                showAddEditModal(event, talk);
              }}
            >
              <td>
                {talk.week > 0 ? (
                  <strong>{format(talk.date, "MMMM dd, yyyy")}</strong>
                ) : (
                  format(talk.date, "MMMM dd, yyyy")
                )}
              </td>
              <td>
                {talk.name}
                {talk.name && talk.talkcount > 1 && (
                  <span
                    title={`${talk.name} is also giving a talk at home on this day`}
                  >
                    <AlertTriangle />
                  </span>
                )}
              </td>
              {talk.outlineno < 900 ? (
                <>
                  <td>{talk.outlineno}</td>
                  <td>
                    {talk.outlinetitle}
                    {talk.outlineno &&
                      talk.outlinecount === 0 && (
                        <Warning
                          tooltip={`${talk.name} is not assigned to give this talk`}
                          onClick={(event) => handleAddTalkOutline(event, talk)}
                        />
                      )}
                    
                  </td>
                </>
              ) : (
                <td colSpan={2}>
                  <strong>{talk.outlinetitle}</strong>
                </td>
              )}
              <td>{talk.congname}</td>
              <td>{getTalkTime(talk)}</td>
              <td>
                <Button variant="icon" size="sm">
                  <Edit />
                </Button>
                {talk.talkid && (
                  <>
                    <Button
                      variant="icon"
                      size="sm"
                      onClick={(event) => {
                        handleDeleteTalk(event, talk);
                      }}
                    >
                      <Trash2 />
                    </Button>
                    <Button
                      variant="icon"
                      size="sm"
                      onClick={(event) => {
                        showAddEditModal(event, {
                          dateid: talk.dateid,
                          date: talk.date,
                          year: talk.year,
                        } as AwayTalk);
                      }}
                    >
                      <Plus />
                    </Button>
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default ManageAwayTalks;
