import axios from "axios";
import { BASE_URL } from "..";

export type HomeTalk = {
  talkid: number;
  dateid: number;
  sunday: string;
  date: Date;
  name: string;
  userid: number;
  outlineno: number;
  outlinetitle: string;
  congname: string;
  chairman: string;
  wtreader: string;
  year: number;
  week: number;
  lastdate?: Date;
  talkcount: number;
  outlinecount: number;
};
export type HomeTalks = HomeTalk[];

export const getHomeTalks = async (week: number) =>
  await axios<HomeTalks>({
    method: "get",
    url: `${BASE_URL}/api/home`,
    params: {
      week,
    },
  })
    .then((response) => response.data)
    .then((talks) =>
      talks.map((talk) => ({
        ...talk,
        date: new Date(talk.sunday),
        lastdate: talk.lastdate ? new Date(talk.lastdate) : undefined,
      }))
    );

export const addHomeTalk = async (talk: HomeTalk) =>
  await axios({
    method: "post",
    url: `${BASE_URL}/api/home`,
    data: talk,
  }).then((response) => response.data);

export const updateHomeTalk = async (talk: HomeTalk) =>
  await axios({
    method: "put",
    url: `${BASE_URL}/api/home/${talk.talkid}`,
    data: talk,
  }).then((response) => response.data);

export const deleteHomeTalk = async (talk: HomeTalk) =>
  await axios({
    method: "delete",
    url: `${BASE_URL}/api/home/${talk.talkid}`,
  }).then((response) => response.data);
