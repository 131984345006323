import { type ReactNode, createContext, useContext, useState } from "react";
import { type UserCongregation, checkUser } from "../client/user";
import { useLocation, useNavigate } from "react-router-dom";

export interface UserContextValue {
  user?: UserCongregation;
  setUser: (user?: UserCongregation) => void;
  isLoggedIn: () => boolean;
  isSuperUser: () => boolean;
}

const UserContext = createContext<UserContextValue | null>(null);

export const useUserContext = () => {
  const context = useContext(UserContext);

  if (context === null) {
    throw new Error("Tried to read UserContext outside of a UserContext");
  }

  return context;
};

const UserContextProvider = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [user, setUser] = useState<UserCongregation | undefined>(undefined);

  const isLoggedIn = () => {
    if (user == null && location.pathname !== "/login") {
      checkUser()
        .then((user) => {
          setUser(user);
        })
        .catch(() => {
          navigate("/login");
        });
    }
    return user !== undefined;
  };

  const isSuperUser = () => user?.userid === 1;

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        isLoggedIn,
        isSuperUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
