import axios from "axios";
import { BASE_URL } from "..";

export interface Congregation {
  congid: number;
  congname: string;
  addressline1: string;
  addressline2: string;
  town: string;
  county: string;
  postcode: string;
  timeodd: string;
  timeeven: string;
  meetingday: number;
}
export type Congregations = Congregation[];

export const getCongregations = async () =>
  await axios<Congregations>({
    method: "get",
    url: `${BASE_URL}/api/congregations`,
  }).then((response) => response.data);

export const addCongregation = async (congregation: Congregation) =>
  await axios<Congregation>({
    method: "post",
    url: `${BASE_URL}/api/congregations`,
    data: congregation,
  }).then((response) => response.data);

export const updateCongregation = async (
  congregation: Congregation,
  oldCongregation: Congregation
) =>
  await axios<Congregation>({
    method: "put",
    url: `${BASE_URL}/api/congregations/${oldCongregation.congid}`,
    data: congregation,
  }).then((response) => response.data);

export const deleteCongregation = async (congregation: Congregation) =>
  await axios<Congregation>({
    method: "delete",
    url: `${BASE_URL}/api/congregations/${congregation.congid}`,
  }).then((response) => response.data);
