import React from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { type Outline } from "../../client/outlines";

export interface EditOutlineProps {
  show: boolean;
  outline?: Outline;
  onSave: (outline: Outline) => void;
  onClose: VoidFunction;
}
const EditOutline = ({ show, onSave, onClose, outline }: EditOutlineProps) => {
  const onFormSubmit = (e: any) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const outline = Object.fromEntries(formData.entries()) as any;
    onSave(outline);
  };
  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        {outline != null ? (
          <Modal.Title>Edit Outline</Modal.Title>
        ) : (
          <Modal.Title>Add Outline</Modal.Title>
        )}
      </Modal.Header>
      <Form onSubmit={onFormSubmit}>
        <Modal.Body>
          <Form.Group controlId="outlineno">
            <Form.Label>Outline No.</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter number"
              name="outlineno"
              defaultValue={outline?.outlineno}
            />
            <Form.Text className="text-muted">
              Note: Special codes have numbers greater than 900
            </Form.Text>
          </Form.Group>
          <Form.Group controlId="outlinetitle">
            <Form.Label>Talk Title</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter talk title"
              name="outlinetitle"
              defaultValue={outline?.outlinetitle}
            />
          </Form.Group>
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={onClose} variant="secondary">
            Close
          </Button>
          <Button type="submit" variant="primary">
            Save changes
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default EditOutline;
