import React from "react";
import { Button, Form, Modal } from "react-bootstrap";

export interface ConfirmModalProps {
  show: boolean;
  title?: string;
  message: string;
  onConfirm: VoidFunction;
  onClose: VoidFunction;
  confirmText?: string;
  cancelText?: string;
}
const ConfirmModal = ({
  show,
  onClose,
  onConfirm,
  title = "Are you sure?",
  message,
  confirmText = "Yes",
  cancelText = "No",
}: ConfirmModalProps) => {
  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <Button onClick={onClose} variant="secondary">
          {cancelText}
        </Button>
        <Button onClick={onConfirm} variant="primary">
          {confirmText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmModal;
