import React, { useState } from "react";
import { useUserContext } from "../hooks/useUserContext";

const SecurePage = ({children}: {children: React.ReactNode}) => {
  const { isLoggedIn } = useUserContext();

  if (isLoggedIn()) {
    return (<>{children}</>)
  }
  return <></>
}

export default SecurePage;
