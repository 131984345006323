import axios from "axios";
import { type Congregation } from "./congregations";
import { Speaker } from "./speakers";
import { BASE_URL } from "..";

export interface User {
  userid: number;
  email: number;
  password: string;
  role: number;
}

export type UserCongregation = User & Congregation;

export const login = async (user: User) =>
  await axios({
    method: "post",
    url: `${BASE_URL}/api/user/login`,
    data: user,
  }).then((response) => response.data);

export const checkUser = async () =>
  await axios<UserCongregation>({
    method: "get",
    url: `${BASE_URL}/api/user/check`,
  }).then((response) => response.data);

export const logout = async () =>
  await axios({
    method: "post",
    url: `${BASE_URL}/api/user/logout`,
    data: {},
  }).then((response) => response.data);

export const jumpCongregation = async (cong: Congregation) =>
  await axios({
    method: "post",
    url: `${BASE_URL}/api/user/jump/congregation/${cong.congid}`,
  }).then((response) => response.data);

export const jumpUser = async (user: Speaker) =>
  await axios({
    method: "post",
    url: `${BASE_URL}/api/user/jump/user/${user.userid}`,
  }).then((response) => response.data);
