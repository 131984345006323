import React, { useEffect, useState, type MouseEvent } from "react";
import { Button, Table, Toast, ToastContainer } from "react-bootstrap";
import { format } from "date-fns";
import {
  deleteHomeTalk,
  getHomeTalks,
  type HomeTalk,
  type HomeTalks,
  updateHomeTalk,
  addHomeTalk,
  addSpeakerOutline,
} from "../../client";
import EditHomeTalk from "./EditHomeTalk";
import ConfirmModal from "../../components/ConfirmModal";
import {
  AlertTriangle,
  ChevronLeft,
  ChevronRight,
  Edit,
  Printer,
  Trash2,
} from "react-feather";
import { useUserContext } from "../../hooks/useUserContext";
import { BASE_URL } from "../..";
import Warning from "../../components/Warning";

function ManageHomeTalks() {
  const user = useUserContext();

  const [week, setWeek] = useState(0);
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [editTalk, setEditTalk] = useState<HomeTalk | undefined>(undefined);
  const [talks, setTalks] = useState<HomeTalks>([]);
  const [showAddTalk, setShowAddTalk] = useState(false);

  const handleCloseAddTalkModal = () => setShowAddTalk(false);

  const addTalkOutline = async () => {
    if (editTalk) {
      await addSpeakerOutline(editTalk.userid, editTalk.outlineno);
      handleCloseAddTalkModal();
      await refresh(week);
    }
  };

  const handleEditClose = () => {
    setShowEdit(false);
  };
  const handleDeleteClose = () => {
    setShowDelete(false);
  };

  const refresh = async (week: number) => {
    setWeek(week);
    await getHomeTalks(week).then((talks) => {
      setTalks(talks);
    });
  };

  const skipWeeks = async (skipWeeks: number) => {
    await refresh(week + skipWeeks);
  };

  const showAddEditModal = (talk?: HomeTalk) => {
    setEditTalk(talk);
    setShowEdit(true);
  };
  const handleDeleteTalk = (
    event: MouseEvent<HTMLButtonElement>,
    talk: HomeTalk
  ) => {
    event.stopPropagation();
    setEditTalk(talk);
    setShowDelete(true);
  };
  const handleAddTalkOutline = (
    event: MouseEvent<HTMLButtonElement>,
    talk: HomeTalk
  ) => {
    event.stopPropagation();
    setEditTalk(talk);
    setShowAddTalk(true);
  };
  const handleDeleteConfirm = async () => {
    if (editTalk != null) await deleteHomeTalk(editTalk);
    setShowDelete(false);
    refresh(week);
  };
  const handleSave = async (talk: HomeTalk) => {
    if (editTalk != null && editTalk.talkid) {
      talk.talkid = editTalk.talkid;
      await updateHomeTalk(talk);
    } else {
      talk.dateid = editTalk!!.dateid;
      await addHomeTalk(talk);
    }
    setShowEdit(false);
    await refresh(week);
  };

  useEffect(() => {
    refresh(week);
  }, []);

  return (
    <>
      <h1>Home Talks</h1>
      {editTalk && (
        <>
          <EditHomeTalk
            show={showEdit}
            onClose={handleEditClose}
            onSave={handleSave}
            talk={editTalk}
          />
          <ConfirmModal
            show={showDelete}
            onClose={handleDeleteClose}
            onConfirm={handleDeleteConfirm}
            message={`Are you sure you want to delete talk on ${format(
              editTalk.date,
              "dd MMMM yyyy"
            )}?`}
          />
          <ConfirmModal
            show={showAddTalk}
            title="Talk not assigned"
            message={`Add talk ${editTalk.outlineno} - ${editTalk.outlinetitle} to ${editTalk.name}'s reportoire?`}
            onConfirm={addTalkOutline}
            onClose={handleCloseAddTalkModal}
          />
        </>
      )}
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>
              <Button variant="icon" size="sm" onClick={() => skipWeeks(-10)}>
                <ChevronLeft />
              </Button>
              Date
              <Button variant="icon" size="sm" onClick={() => skipWeeks(+10)}>
                <ChevronRight />
              </Button>
            </th>
            <th>Speaker</th>
            <th>Talk No.</th>
            <th>Title</th>
            <th>From Congregation</th>
            <th>Chairman</th>
            <th>WT Reader</th>
            <th>
              <a href={`${BASE_URL}/api/print/home?week=${week}`}>
                <Button variant="icon" size="sm">
                  <Printer />
                </Button>
              </a>
            </th>
          </tr>
        </thead>
        <tbody>
          {talks.map((talk, index) => (
            <tr
              key={index}
              onClick={() => {
                showAddEditModal(talk);
              }}
            >
              <td>
                {talk.week > 0 ? (
                  <strong>{format(talk.date, "MMMM dd, yyyy")}</strong>
                ) : (
                  format(talk.date, "MMMM dd, yyyy")
                )}
              </td>
              <td>
                {talk.name}
                {talk.name && talk.talkcount > 1 && (
                  <Warning
                    tooltip={`${talk.name} is also giving an Away talk on this day`}
                  />
                )}
              </td>
              {talk.outlineno > 0 && talk.outlineno < 900 ? (
                <>
                  <td>{talk.outlineno}</td>
                  <td>
                    {talk.outlinetitle}
                    {talk.outlineno &&
                      talk.outlinecount === 0 &&
                      talk.congname === user.user?.congname && (
                        <Warning
                          tooltip={`${talk.name} is not assigned to give this talk`}
                          onClick={(event) => handleAddTalkOutline(event, talk)}
                        />
                      )}
                    {talk.outlineno && talk.lastdate && (
                      <Warning
                        tooltip={`This talk was last heard on ${format(
                          talk.lastdate,
                          "dd MMMM yyyy"
                        )}`}
                      />
                    )}
                  </td>
                </>
              ) : (
                <td colSpan={2}>
                  <strong>{talk.outlinetitle}</strong>
                </td>
              )}
              <td>{talk.congname}</td>
              <td>{talk.chairman}</td>
              <td>{talk.wtreader}</td>
              <td>
                <Button variant="icon" size="sm">
                  <Edit />
                </Button>
                {talk.talkid && (
                  <Button
                    variant="icon"
                    size="sm"
                    onClick={(event) => {
                      handleDeleteTalk(event, talk);
                    }}
                  >
                    <Trash2 />
                  </Button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}

export default ManageHomeTalks;
