import axios from "axios";
import { Congregation } from "./congregations";
import { BASE_URL } from "..";

export type Speaker = {
  congid: number;
  userid: number;
  username: string;
  useremail: string;
  usermobile: string;
  role: number;
  outlines?: number[];
};
export type Speakers = Speaker[];
export type CongregationSpeaker = Speaker & Congregation;
export type CongregationSpeakers = CongregationSpeaker[];

export type SpeakerOutline = {
  userid: number;
  outlineno: number;
};
export type SpeakerOutlines = SpeakerOutline[];

export const getSpeakers = async (congname?: string) =>
  await axios<CongregationSpeakers>({
    method: "get",
    url: `${BASE_URL}/api/speakers`,
    params: {
      congname,
    },
  }).then((response) => response.data);

export const getSpeakersByCongregation = async (congid: number) =>
  await axios<CongregationSpeakers>({
    method: "get",
    url: `${BASE_URL}/api/speakers/congregation/${congid}`,
  }).then((response) => response.data);

export const addSpeaker = async (speaker: Speaker) =>
  await axios({
    method: "post",
    url: `${BASE_URL}/api/speakers`,
    data: speaker,
  }).then((response) => response.data);

export const updateSpeaker = async (speaker: Speaker) =>
  await axios({
    method: "put",
    url: `${BASE_URL}/api/speakers/${speaker.userid}`,
    data: speaker,
  }).then((response) => response.data);

export const updatePassword = async (speaker: Speaker) =>
  await axios({
    method: "put",
    url: `${BASE_URL}/api/speakers/${speaker.userid}/password`,
    data: speaker,
  }).then((response) => response.data);

export const deleteSpeaker = async (speaker: Speaker) =>
  await axios({
    method: "delete",
    url: `${BASE_URL}/api/speakers/${speaker.userid}`,
  }).then((response) => response.data);

export const getSpeakerOutlines = async (userid: number) =>
  await axios<SpeakerOutlines>({
    method: "get",
    url: `${BASE_URL}/api/speakers/${userid}/outlines`,
  }).then((response) => response.data);

export const addSpeakerOutline = async (userid: number, outlineno: number) =>
  await axios<SpeakerOutlines>({
    method: "PUT",
    url: `${BASE_URL}/api/speakers/${userid}/outlines/${outlineno}`,
  }).then((response) => response.data);
